.arrowright {
    pointer-events: none;
    animation: ltrmovebig infinite 1s linear;
  }

  @keyframes ltrmovebig {
    0% {
      left: 320px;
    }
    50% {
      left: 300px;
    }
    100% {
      left: 320px;    
    }
  }

  .arrowleft {
    pointer-events: none;
    animation: ltrmovesmall infinite 1s linear;
  }

  @keyframes ltrmovesmall {
    0% {
      left: 180px;
    }
    50% {
      left: 160px;
    }
    100% {
      left: 180px;    
    }
  }